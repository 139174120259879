
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

$custom-typography: mat-typography-config(
  $font-family: null,
  $headline: mat-typography-level(null, null, null, null, null),
  $title: mat-typography-level(null, null, null, null, null),
  $subheading-2 : mat-typography-level(null, null, null, null, null),
  $subheading-1 : mat-typography-level(null, null, null, null, null),
  $body-1: mat-typography-level(null, null, null, null, null),
  $body-2: mat-typography-level(null, null, null, null, null)
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($custom-typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/


$askafox-brown: (
  50: #efebe9,
  100: #d7ccc8,
  200: #bcaaa4,
  300: #a1887f,
  400: #8d6e63,
  500: #332200,
  600: #6d4c41,
  700: #5d4037,
  800: #4e342e,
  900: #3e2723,
  A100: #d7ccc8,
  A200: #bcaaa4,
  A400: #8d6e63,
  A700: #5d4037,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$askafox-primary: mat-palette($askafox-brown);
$askafox-accent: mat-palette($mat-orange, A400);

// The warn palette is optional (defaults to red).
$askafox-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$askafox-theme: mat-light-theme((
  color: (
    primary: $askafox-primary,
    accent: $askafox-accent,
    warn: $askafox-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($askafox-theme);

.mat-h1, .mat-headline, .mat-typography h1 {
  margin-top: 0;
  margin-bottom: .5rem;

}

.mat-h2, .mat-title, .mat-typography h2 {
  margin-top: 0;
  margin-bottom: .5rem;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  margin-top: 0;
  margin-bottom: .5rem;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  margin-top: 0;
  margin-bottom: .5rem;
}

.mat-h5, .mat-typography h5 {
  margin-top: 0;
  margin-bottom: .5rem;
}

.mat-h6, .mat-typography h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

.mat-body p, .mat-body-1 p, .mat-typography p {
  margin-top: 0;
  margin-bottom: 1.5rem;
}

// Input style
// Without input underline
.mat-input-underline {
  display: none;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}

//text height
.mat-form-field {
  line-height : inherit;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid #d9e2e0;
}

// Tab label
.mat-tab-label-active {
  border: 1px solid #d9e2e0;
}

.mat-tab-label, .mat-tab-link {
  color: #332200;
}

.mat-ink-bar {
  background-color: #FF9100 !important;
}

// Checkbox group
.mat-tab-body-wrapper {
  padding: 23px 15px;
  margin-top: -1px;
  border: 1px solid #d9e2e0;
  border-top-color: rgb(217, 226, 224);
  border-top-style: solid;
  border-top-width: 1px;
  border-right-color: rgb(217, 226, 224);
  border-right-style: solid;
  border-right-width: 1px;
  border-bottom-color: rgb(217, 226, 224);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left-color: rgb(217, 226, 224);
  border-left-style: solid;
  border-left-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}

@media only screen and (min-width: 768px) {
  .mat-tab-body-wrapper {
      padding: 23px 30px;
  }
}

.mat-checkbox-label {
  font-size: 14px;
  font-weight: 500;
}

mat-checkbox label {
  margin-top: 0.4em;
}

mat-checkbox .form__label-text {
  padding-left: 10px;
}

.mat-grid-tile .mat-grid-tile-content {
  align-items: start !important;
  justify-content: left !important;
}

//tab inactive scroll bar (Page : find a supplier, ask an expert, sign up)
.mat-tab-body-content{
  overflow: hidden !important; 
}

//Sign in
#sign_in .mat-tab-label-active {
  border: none;
}
#sign_in .mat-ink-bar {
  display: none;
}

#sign_in .mat-tab-body-wrapper {
  border: none;
}

#sign_in .mat-tab-nav-bar, .mat-tab-header {
  border: none;
}

//FAQ
.mat-expansion-panel-header {
  height: 70px;
}

// Mat error long message
mat-form-field {
  &.ng-valid {
    .mat-form-field-wrapper {
      padding-bottom: 1.25em;
    }
  }

  &.ng-invalid,
  &.mat-form-field-invalid {
    .mat-form-field-wrapper {
      padding-bottom: 7px;
    }
  }

  &.ng-untouched {
    .mat-form-field-wrapper {
      padding-bottom: 1.25em;
    }
  }

  .mat-form-field {
    &-underline {
      position: static;
    }

    &-subscript-wrapper {
      position: static;
    }
  }
}